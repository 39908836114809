import { Card, Flex, Table, Text } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableCardWrapper } from "@/app/components/table-card-wrapper/table-card-wrapper";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { RecsAssignTableProps } from "./recs-assigned-list-table.types";

export const RecsAssignedListTable = ({
  tableColumns,
  tableData,
  reloadTable,
  loading,
  totalAssignedRecs,
}: RecsAssignTableProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: 2,
        mt: 2,
      }}
    >
      <Card variant="compact">
        <TableCardWrapper sx={{ fontWeight: 600 }}>
          <Text>
            {t("Total Assigned RECs: {{value}}", {
              value: totalAssignedRecs,
            })}
          </Text>
          <RefreshIconButton loading={false} fetchData={reloadTable} />
        </TableCardWrapper>
        <Flex
          sx={{
            flexDirection: "column",
            table: {
              td: {
                whiteSpace: "unset",
              },
            },
          }}
        >
          <Table
            columns={tableColumns}
            dataSource={tableData}
            translation={getTableTranslation(t)}
            loading={loading}
            pagination={false}
            containerSx={{
              table: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
              },
            }}
          />
        </Flex>
      </Card>
    </Flex>
  );
};
