import { format } from "date-fns";
import { find } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTransactionFee } from "@/app/hooks/use-transaction-fee";
import { AppDateFormats } from "@/app/lib/format-date";
import { useTenantsQuery } from "@/app/types/generated/graphql";

import { getProductAttributesAsDisplayAttributes } from "../order-modal";
import {
  ForwardTradeDetailsModalCustomProps,
  ForwardTradeDetailsSummaryAttributes,
} from "./forward-trade-details-modal.types";

export const useForwardTradeDetailsModal = ({
  tradeContract,
}: ForwardTradeDetailsModalCustomProps): ForwardTradeDetailsSummaryAttributes => {
  const { t } = useTranslation();
  const { data: tenantsData, loading: tenantsLoading } = useTenantsQuery();

  const [currencySymbol] = useMemo(() => {
    const tenant = find(tenantsData?.tenants, { id: tradeContract.tenantId });
    return [tenant?.localisation?.currencySymbol ?? "$"];
  }, [tradeContract.tenantId, tenantsData]);

  const { getAppliedFee, loading: feeLoading } = useTransactionFee();
  const fee = useMemo(() => getAppliedFee(tradeContract.position), [getAppliedFee, tradeContract.position]);

  const productAttributes = useMemo(() => {
    return getProductAttributesAsDisplayAttributes(t, {
      attributes: tradeContract.attributes,
      formattedAttributes: tradeContract.formattedAttributes,
    });
  }, [t, tradeContract]);

  const counterPartyAttributes = useMemo(
    () => [
      {
        label: "Name",
        value: tradeContract.counterAccount?.company?.businessName ?? "-",
      },
      {
        label: "Email",
        value: tradeContract.counterAccount?.email ?? "-",
      },
    ],
    [tradeContract.counterAccount],
  );

  const orderSummaryAttributes = useMemo(
    () => [
      {
        label: "Order Number",
        value: tradeContract.tradeContractNumber,
      },
      {
        label: "Trade Lodgement Date",
        value: format(new Date(tradeContract.createdDate ?? ""), AppDateFormats.AbbreviatedMonthFormat),
      },
      {
        label: "Trade Settlement Date",
        value: format(new Date(tradeContract.tradeSettlementDate ?? ""), AppDateFormats.AbbreviatedMonthFormat),
      },
    ],
    [tradeContract],
  );

  const forwardSummaryAttributes = [
    {
      title: t("Product Attributes"),
      attributes: productAttributes,
    },
    ...(tradeContract.counterAccount
      ? [
          {
            title: t("Counter Party Details"),
            attributes: counterPartyAttributes,
          },
        ]
      : []),
  ];

  return {
    fee,
    loading: tenantsLoading || feeLoading,
    currencySymbol,
    forwardSummaryAttributes,
    orderSummaryAttributes,
  };
};
