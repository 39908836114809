import { Flex, Text, ThemeUIStyleObject } from "@powerledger/ui-component-lib";

export type DisplayAttributeProps = {
  label: string;
  value: string;
};

export const DisplayForwardOrderAttribute = ({
  label,
  value,
  sx = {},
}: DisplayAttributeProps & {
  sx?: ThemeUIStyleObject;
}) => {
  return (
    <Flex sx={{ flexDirection: "row", alignItems: "center", gap: 3, fontSize: 1, ...sx }}>
      <Text sx={{ minWidth: 180, maxWidth: 180, fontWeight: 500 }}>{label}</Text>
      <Text sx={{ wordBreak: "break-word" }}>{value}</Text>
    </Flex>
  );
};
