import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { ForwardTradeWithdrawModalViewProps } from "./forward-trade-withdraw-modal.types";

export const ForwardTradeWithdrawModalView = ({
  visible,
  closeModal,
  loading,
  onWithdrawAction,
}: ForwardTradeWithdrawModalViewProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      title={t("Are you sure you want to withdraw this trade?")}
      subtitle={t("Please note that withdrawing the trade will remove it from the market.")}
    >
      <Flex sx={{ justifyContent: "flex-end", mt: 4, gap: 2 }}>
        <Button variant="error" onClick={closeModal} sx={{ minWidth: 80 }} disabled={loading}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={onWithdrawAction} disabled={loading}>
          {t("Confirm")}
        </Button>
        {loading && <IconLoading size="small" />}
      </Flex>
    </Modal>
  );
};
