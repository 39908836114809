import { Option } from "@powerledger/ui-component-lib";

import { FilterState } from "@/app/components/page-header/filter/filter.types";
import { FormattedOption, RECOrderAttributeOptions } from "@/app/lib/format-rec-options";

export const getNewStateWithAllOrNoneForAKey = (
  values: Array<string>,
  baseOptions: FormattedOption,
  all = true,
  key?: string,
) => {
  if (!key) {
    const thisKeyValues: string[] = baseOptions.map((opt) => opt.value);
    // If all is true, return all options else return empty array
    return all ? thisKeyValues : [];
  }
  const thisKeyOption = baseOptions.find((opt) => opt.label === key)?.options || [];
  const thisKeyValues: string[] = thisKeyOption?.map((opt) => opt.value);
  const otherKeyValues = values.filter((value) => !thisKeyOption.find((val) => value == val.value));
  return all ? [...otherKeyValues, ...thisKeyValues] : otherKeyValues;
};

export const getOptionForType = (type: keyof FilterState, options?: RECOrderAttributeOptions) => {
  switch (type) {
    case "vintages":
      return options?.vintageOptions;
    case "certifications":
      return options?.certificationOptions;
    case "fuelSources":
      return options?.fuelSourceOptions;
    case "eligibilities":
      return options?.eligibilityOptions;
    case "locations":
      return options?.locationOptions;
    case "projects":
      return options?.projectOptions;

    default:
      return [];
  }
};

/** For Selecting All And Deselect All Values for a key in Multi Selector */
export enum MultiSelectActions {
  AllSelected = "ALL_SELECTED",
  AllDeselected = "ALL_DESELECTED",
}

/**
 *
 * Get location options with All appended on each group key
 * eg: Canada - All, Alabama .... USA - All, Minnesota ...
 *
 */
export const getLocationOptions = ({
  options,
  values,
  allSelectedLabel,
  isMulti = true,
}: {
  allSelectedLabel?: string;
  options: FormattedOption;
  values?: Option[];
  isMulti?: boolean;
}) => {
  const locationsSelected =
    options?.map((o) => {
      const selected = (o.options || []).filter((so) => values?.find((p) => p.value === so.value)) || [];
      return {
        key: o.label,
        count: selected.length,
        allSelected: o.options?.length === selected.length,
      };
    }) || [];

  const allSelected: Record<string, boolean> = {};
  for (const s of locationsSelected) {
    allSelected[s.key || ""] = s.allSelected;
  }

  const displayOptions =
    options?.map((opt) => ({
      ...opt,
      options: [
        ...(isMulti
          ? [
              {
                label: allSelectedLabel ?? "",
                key: opt.label,
                value: allSelected[opt.label] ? MultiSelectActions.AllDeselected : MultiSelectActions.AllSelected,
              },
            ]
          : []),
        ...(opt.options || []),
      ],
    })) || [];

  return displayOptions;
};

const addHalfToVintageOptions = (vintage: FormattedOption[0], halfOption: FormattedOption) =>
  halfOption.map((opt) => ({
    label: opt.label,
    value: `${vintage.label}-${opt.value}`,
  }));

export const getVintageOptions = (options?: RECOrderAttributeOptions): FormattedOption =>
  options?.vintageOptions.map((opt) => ({
    ...opt,
    options: [{ label: opt.label, value: opt.value }, ...addHalfToVintageOptions(opt, options?.vintageHalfOptions)],
  })) ?? [];
