import { Option } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { subYears } from "date-fns";
import { FormikHelpers } from "formik";
import { find } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";

import { notifications } from "@/app/container/notifications";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { useTransactionFee } from "@/app/hooks/use-transaction-fee/use-transaction-fee";
import { getDatePart } from "@/app/lib/date-helpers";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import {
  AccountType,
  AssetType,
  CurrencyCode,
  RegistryCode,
  TradePosition,
  TradeType,
  useCreateTradeContractMutation,
  useTenantsQuery,
} from "@/app/types/generated/graphql";

import { useFilter } from "../../page-header/filter";
import { FilterValue, StackedValues, UseFilter } from "../../page-header/filter/filter.types";
import { OrderLifeCycle } from "../common";
import { ForwardTradeFormValues, UseForwardTradeModal } from "./forward-trade-order-modal.types";

export const useForwardTradeModal: UseForwardTradeModal = ({ closeModal, defaultAsset }) => {
  const { t } = useTranslation();
  const { accountData, loading: recOptionsLoading } = useRecOptions();

  const [tradePosition, setTradePosition] = useState<TradePosition>(TradePosition.Bid);
  const isPositionBid = tradePosition === TradePosition.Bid;
  const [currentStep, setCurrentStep] = useState(OrderLifeCycle.Form);
  const isSellOrderDisabled = accountData?.account?.type === AccountType.NonRegistry;

  const [overLimitError, setOverLimitError] = useState<string>();
  const { data: tenantsData } = useTenantsQuery();
  const [createOrder] = useCreateTradeContractMutation();

  const { getAppliedFee, loading } = useTransactionFee();

  const [currencyCode, currencySymbol] = useMemo(() => {
    const tenant = find(tenantsData?.tenants, { id: accountData?.account?.tenantId });
    return [tenant?.localisation?.currencyCode as CurrencyCode, tenant?.localisation?.currencySymbol || "$"];
  }, [accountData?.account?.tenantId, tenantsData]);

  const filterArgs = useMemo(() => {
    const args: UseFilter["arguments"] = {
      hideStatusFilter: true,
      hideProjectFilter: true,
      shouldSelectVintage: true,
      filterFormFieldProps: {
        vintages: {
          label: t("Vintage *"),
          isMulti: isPositionBid,
          info:
            !isPositionBid && defaultAsset?.vintages?.length
              ? t(
                  "Only the first option from vintage filter is applied. If you want to change the selected vintage, please clear the applied filter in the forward market first.",
                )
              : "",
        },
        eligibilities: {
          info: t(
            "Eligibilities exist to inform users that Certificates meet the requirements of different external voluntary or compliance programs managed by states",
          ),
        },
        cod: {
          label: t("Commencement of Operations Date (COD)"),
          dynamicInfo: (filterValues: FilterValue[]) =>
            filterValues.length
              ? t(`RECs that are from projects no older than {{date}} will be shown.`, {
                  date: getDatePart(subYears(new Date(), +filterValues?.[0]?.value)),
                })
              : "",
        },
      },
      ...(defaultAsset
        ? {
            initialValues: { ...defaultAsset, statuses: [] as FilterValue[] },
          }
        : {}),
    };
    return args;
  }, [defaultAsset, isPositionBid, t]);

  const { filters, filterValues, stackedValues, removeValue, resetFilter } = useFilter(filterArgs);
  const displayedStackedValues = useMemo(() => {
    return isPositionBid
      ? stackedValues
      : stackedValues.reduce((acc: StackedValues, value) => {
          if (value.type === "vintages" && acc.some((val) => val.type === "vintages")) {
            return acc;
          }

          if (value.type === "vintages") {
            const firstVintageInFilter = filterValues.vintages[0];
            const matchingStackedVintageValue = stackedValues.find(
              (item) =>
                item.type === "vintages" &&
                firstVintageInFilter.key === item.key &&
                firstVintageInFilter.value === item.value,
            );
            matchingStackedVintageValue && acc.push(matchingStackedVintageValue);
            return acc;
          }

          acc.push(value);
          return acc;
        }, []);
  }, [isPositionBid, stackedValues, filterValues.vintages]);

  const handleSubmit = useCallback(
    async (values: ForwardTradeFormValues, actions: FormikHelpers<ForwardTradeFormValues>) => {
      try {
        await createOrder({
          variables: {
            input: {
              tradeContractInput: {
                registryCode: RegistryCode.Mrets,
                assetType: AssetType.Rec,
                tradeType: TradeType.Forward,
                currencyCode,
                position: tradePosition,
                volume: Number(values.volume),
                unitPrice: currency(values.unitPrice).intValue.toString(),
                tradeSettlementDate: new Date(values.settlementDate).toISOString().split("T")[0],
              },
              recAttributeInput: {
                vintages: isPositionBid
                  ? filterValues.vintages.map((selectedOption) => selectedOption.value)
                  : [filterValues.vintages[0].value],
                eligibilities: filterValues.eligibilities.map((selectedOption) => selectedOption.value),
                locations: filterValues.locations.map((selectedOption) => selectedOption.value),
                fuelSources: filterValues.fuelSources.map((selectedOption) => selectedOption.value),
                certifications: filterValues.certifications.map((selectedOption) => selectedOption.value),
                commencementDate: filterValues.cod.length
                  ? getDatePart(subYears(new Date(), +filterValues.cod?.[0]?.value))
                  : undefined,
              },
            },
          },
          refetchQueries: ["TradeContracts"],
        });
        setCurrentStep(OrderLifeCycle.Success);
      } catch (e) {
        e instanceof Error &&
          notifications.error({
            description: t(e?.message),
          });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [
      createOrder,
      currencyCode,
      filterValues.certifications,
      filterValues.cod,
      filterValues.eligibilities,
      filterValues.fuelSources,
      filterValues.locations,
      filterValues.vintages,
      isPositionBid,
      t,
      tradePosition,
    ],
  );

  const toggleTradePosition = useCallback(() => {
    setTradePosition((prev) => (prev === TradePosition.Bid ? TradePosition.Ask : TradePosition.Bid));
    resetFilter();
  }, [resetFilter]);

  const formikInitialValue: ForwardTradeFormValues = useMemo(
    () => ({
      volume: 0,
      unitPrice: 0,
      fee: getAppliedFee(tradePosition),
      settlementDate: defaultAsset?.selectedDate[0]?.value ?? "",
      vintage: defaultAsset?.vintages?.map((vintage) => vintage.value).join(",") ?? "",
    }),
    [getAppliedFee, tradePosition, defaultAsset?.selectedDate, defaultAsset?.vintages],
  );

  const handleFieldChange = (
    name: "settlementDate" | "vintage",
    value: any,
    setFieldValue: FormikHelpers<ForwardTradeFormValues>["setFieldValue"],
  ) => {
    if (name === "settlementDate") {
      const formattedDate = formatDate(value?.toString(), {
        formatStyle: AppDateFormats.HyphenUniversalDateFormat,
        noTZ: true,
      });
      setFieldValue(name, formattedDate.trim());
    } else if (name === "vintage") {
      if (Array.isArray(value)) {
        const _value = value.map((v) => v.value).join(",");
        setFieldValue(name, _value);
        return;
      }

      const singleValue = value as SingleValue<Option>;
      if (singleValue) {
        setFieldValue(name, singleValue.value);
      } else {
        setFieldValue(name, "");
      }
    }
  };

  return {
    loading,
    filters,
    stackedValues: displayedStackedValues,
    removeValue,
    isFilterValid: !!filterValues.vintages.length,
    formikInitialValue,
    handleSubmit,
    currencySymbol,
    overLimitError,
    recOptionsLoading,
    setOverLimitError,
    closeModal,
    currentStep,
    tradePosition,
    isPositionBid,
    toggleTradePosition,
    isSellOrderDisabled,
    handleFieldChange,
  };
};
