import { Box, Flex, Modal, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { DisplayForwardOrderAttribute } from "../display-forward-order-attributes";
import { LoadingOverlayIndicator } from "../loading-overlay-indicator";
import OrderSummary from "../order-modal/order-summary";
import { ForwardTradeDetailsModalViewProps } from "./forward-trade-details-modal.types";

export const ForwardTradeDetailsModalView: React.FC<ForwardTradeDetailsModalViewProps> = ({
  fee,
  loading,
  visible,
  closeModal,
  tradeContract,
  currencySymbol,
  forwardSummaryAttributes,
  orderSummaryAttributes,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  return (
    <Modal visible={visible} onCancel={closeModal} modalMaxWidth="1000px">
      <Box>
        <Flex sx={{ justifyContent: "space-between" }}>
          <Text sx={{ fontSize: 4, fontWeight: "bold" }}>{t("Forward Trade Summary")}</Text>
        </Flex>
        <Flex>
          <Flex
            sx={{
              flexDirection: "column",
              width: "50%",
              mr: 3,
              maxHeight: "700px",
              overflow: "auto",
              pr: 3,
              mt: 3,
              gap: 5,
            }}
          >
            {forwardSummaryAttributes.map(({ title, attributes }) => (
              <Flex key={title} sx={{ flexDirection: "column" }}>
                <Text sx={{ fontSize: 3, mb: 3, fontFamily: "MintGroteskV08", fontWeight: 600 }}>{title}</Text>
                <Flex sx={{ gap: 1, flexDirection: "column" }}>
                  {attributes.map((props) => (
                    <DisplayForwardOrderAttribute key={props.label} {...props} />
                  ))}
                </Flex>
              </Flex>
            ))}
          </Flex>

          <Flex
            sx={{
              flexDirection: "column",
              width: "50%",
              backgroundColor: "white",
              padding: 3,
              borderRadius: 4,
              height: "100%",
            }}
          >
            <Flex sx={{ flexDirection: "column" }}>
              <Text sx={{ fontSize: 3, mb: 3, fontFamily: "MintGroteskV08", fontWeight: 600 }}>
                {t("Order Summary")}
              </Text>
              <Flex sx={{ gap: 1, flexDirection: "column" }}>
                {orderSummaryAttributes.map((props) => (
                  <DisplayForwardOrderAttribute
                    key={props.label}
                    {...props}
                    sx={{
                      justifyContent: "space-between",
                      "> span": {
                        fontWeight: 400,
                      },
                    }}
                  />
                ))}
              </Flex>
              <OrderSummary
                title=""
                fee={fee}
                orderPosition={tradeContract.position}
                unitPrice={currency(tradeContract.unitPrice ?? 0, { separator: ",", fromCents: true }).value}
                volume={tradeContract.volume}
                currencySymbol={currencySymbol}
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
