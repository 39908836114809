import { Card, Flex, IconWarning, Table, Text } from "@powerledger/ui-component-lib";
import { sumBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableCardWrapper } from "@/app/components/table-card-wrapper/table-card-wrapper";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { RecsAssignFormTableProps } from "./recs-assign-form-table.types";

export const RecsAssignFormTable = ({
  tableColumns,
  tableData,
  reloadTable,
  loading,
  askTradeVolume,
  pageInfo,
  fetchData,
}: RecsAssignFormTableProps) => {
  const { t } = useTranslation();

  //handles negative availableBalance
  const totalAssignableRecs = useMemo(
    () => sumBy(tableData, (item) => Math.max(item.availableBalance, 0)),
    [tableData],
  );

  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: 2,
        mt: 2,
      }}
    >
      <Card variant="compact">
        <TableCardWrapper>
          <Flex
            sx={{
              gap: 2,
              fontWeight: 600,
            }}
          >
            <Text>
              {t("Total Assignable RECs: {{value}}", {
                value: totalAssignableRecs,
              })}
            </Text>
            <Text>|</Text>
            <Text>
              {t("Required RECs: {{value}}", {
                value: askTradeVolume,
              })}
            </Text>
          </Flex>
          <RefreshIconButton loading={false} fetchData={reloadTable} />
        </TableCardWrapper>
        <Flex
          sx={{
            flexDirection: "column",
            table: {
              td: {
                whiteSpace: "unset",
              },
            },
          }}
        >
          <Table
            columns={tableColumns}
            dataSource={tableData}
            translation={getTableTranslation(t)}
            loading={loading}
            manualPagination
            pageInfo={pageInfo}
            fetchData={fetchData}
            containerSx={{
              table: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
              },
            }}
          />
          {!loading && (!tableData.length || totalAssignableRecs === 0) && (
            <Flex
              sx={{
                alignItems: "center",
                mt: 2,
              }}
            >
              <IconWarning
                sx={{
                  svg: {
                    width: 24,
                    height: 16,
                  },
                }}
              />
              <Text
                sx={{
                  fontSize: 14,
                  color: "textDark",
                }}
              >
                {t("You do not have enough RECs available to complete the Forward trade deal.")}
              </Text>
            </Flex>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};
