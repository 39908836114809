import { CheckedSVG, UncheckedSVG } from "@powerledger/ui-component-lib";
import { cloneElement, Fragment, MouseEventHandler } from "react";
import { components as selectComponents, OnChangeValue, OptionProps, ValueContainerProps } from "react-select";
import { Box, Checkbox, Flex, Text } from "theme-ui";

import { FormattedOption } from "@/app/lib/format-rec-options";

import { MultiSelectActions } from "./helpers";

export const ValueContainerComponent = <T extends object, K extends boolean>({
  children,
  ...props
}: {
  children: any;
} & ValueContainerProps<T, K>) => {
  if (!props.hasValue) {
    return <selectComponents.ValueContainer {...props}>{children}</selectComponents.ValueContainer>;
  }

  const CHIPSLIMIT = props.selectProps.chipsLimit || 1;
  const [chips, otherChildren] = children;

  const displayChips = chips.slice(0, CHIPSLIMIT);

  const displayValueText:
    | {
        texts: string[];
        showTexts: boolean;
      }
    | undefined = props.selectProps.additionalProps?.displayValueText;

  /**
   * textElements is used for displaying custom texts passed instead of actual children,
   * the element is cloned from original React Select children to make it adhere to the styles same as other chips
   */
  const textElements = (displayValueText?.texts || []).map((text) => (
    <Fragment key={text}>
      {cloneElement(displayChips[0], {
        children: text,
        /** Added to disable the cross button for custom text */
        data: {
          key: MultiSelectActions.AllDeselected,
        },
      })}
    </Fragment>
  ));

  const overflowCounter = textElements.length ?? props.getValue().length;

  return (
    <selectComponents.ValueContainer {...props}>
      <Flex sx={{ alignItems: "center" }}>
        {displayValueText?.showTexts ? textElements.slice(0, CHIPSLIMIT) : displayChips}
        {overflowCounter > CHIPSLIMIT && (
          <Text sx={{ fontSize: 0, ml: 1, color: "textDarker" }}>{`+${overflowCounter - CHIPSLIMIT}`}</Text>
        )}
      </Flex>
      {otherChildren}
    </selectComponents.ValueContainer>
  );
};

export const VintageValueContainerComponent = <T extends object, K extends boolean>({
  children,
  ...props
}: {
  children: any;
} & ValueContainerProps<T, K>) => {
  if (!props.hasValue) {
    return <selectComponents.ValueContainer {...props}>{children}</selectComponents.ValueContainer>;
  }

  const CHIPSLIMIT = props.selectProps.chipsLimit || 1;
  const [chips, otherChildren] = children;

  const displayChips = chips.slice(0, CHIPSLIMIT);

  const displayValueTexts: string[] | undefined = props.selectProps.additionalProps?.displayValueTexts;

  /**
   * textElements is used for displaying custom texts passed instead of actual children,
   * the element is cloned from original React Select children to make it adhere to the styles same as other chips
   */
  const textElements = (displayValueTexts || []).map((text) => (
    <Fragment key={text}>
      {cloneElement(displayChips[0], {
        children: text,
        /** Added to disable the cross button for custom text */
        data: {
          key: MultiSelectActions.AllDeselected,
        },
      })}
    </Fragment>
  ));

  const overflowCounter = textElements.length;
  return (
    <selectComponents.ValueContainer {...props}>
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        {textElements.slice(0, CHIPSLIMIT)}
        {overflowCounter > CHIPSLIMIT && (
          <Text sx={{ fontSize: 0, ml: 1, color: "textDarker" }}>{`+${overflowCounter - CHIPSLIMIT}`}</Text>
        )}
        {otherChildren}
      </Flex>
    </selectComponents.ValueContainer>
  );
};

export const OptionComponent = <T extends FormattedOption["0"], K extends boolean>({
  children,
  isSelected,
  ...props
}: {
  isSelected?: boolean;
  children: React.ReactNode;
} & OptionProps<T, K>) => {
  if (!children) return <></>;

  const checked = props.data.value === MultiSelectActions.AllDeselected || isSelected;

  return (
    <selectComponents.Option isSelected={false} {...props}>
      <Flex
        sx={{
          gap: 2,
          fontSize: 1,
          alignItems: "center",
          input: { display: "none", zIndex: "-1" },
        }}
      >
        <>
          {checked ? <CheckedSVG /> : <UncheckedSVG />}
          <Box sx={{ display: "none" }}>
            <Checkbox
              checked={checked}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => props.innerProps.onClick?.(e as any)}
            />
          </Box>
        </>
        {children}
      </Flex>
    </selectComponents.Option>
  );
};

export const VintageOptionComponent = <T extends FormattedOption["0"], K extends boolean>({
  children,
  isSelected,
  ...props
}: {
  isSelected?: boolean;
  children: React.ReactNode;
} & OptionProps<T, K>) => {
  if (!children) return <></>;
  if (!Array.isArray(props.selectProps.value)) return <></>;

  const data = props.data;

  const groupedValue = props.selectProps.options.find((opt) => (opt as FormattedOption["0"]).value === data.value);

  const checked = props.selectProps.value.some(
    (x) => x.value.includes(`${props.data.value}-`) || x.value === props.data.value,
  );

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!Array.isArray(props.selectProps.value)) return;
    if (groupedValue) {
      if (!checked) {
        const groupOptions = groupedValue.options?.slice(1) ?? [];
        const firstSelectedOptionFromGroup = groupOptions.length > 0 ? [groupOptions[0]] : [];
        const selectGroupValue = props.isMulti ? groupOptions : firstSelectedOptionFromGroup;
        const selectedValue = props.isMulti ? [...props.selectProps.value, ...selectGroupValue] : selectGroupValue;

        return props.selectProps.onChange(selectedValue as unknown as OnChangeValue<T, K>, {
          action: "select-option",
          name: "select",
          option: { label: props.data.label, value: data.value } as T,
        });
      }
      return props.selectProps.onChange(
        props.selectProps.value?.filter((x) => !x.value.includes(data.value + "-")) as unknown as OnChangeValue<T, K>,
        {
          action: "deselect-option",
          name: "select",
          option: { label: data.label, value: data.value } as T,
        },
      );
    }
    return props.innerProps.onClick?.(e);
  };

  return (
    <selectComponents.Option
      isSelected={false}
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: handleClick,
      }}
    >
      <Flex
        role="button"
        sx={{
          bg: "transparent",
          p: 0,
          gap: 2,
          fontSize: 1,
          alignItems: "center",
          input: { display: "none", zIndex: "-1" },
          ml: groupedValue ? 0 : 3,
        }}
        onClick={handleClick}
      >
        <>
          {checked ? <CheckedSVG /> : <UncheckedSVG />}
          <Box sx={{ display: "none" }}>
            <Checkbox
              checked={checked}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => e.stopPropagation()}
              id={`${props.data.value}`}
            />
          </Box>
        </>
        {children}
      </Flex>
    </selectComponents.Option>
  );
};
